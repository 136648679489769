import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MapToComponents } from 'react-map-to-components';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ThreeColumns from '../sections/ThreeColumns';
import TwoColumns from '../sections/TwoColumns';
import SingleColumn from '../sections/SingleColumn';
import Hero from '../sections/Hero';
import BlockQuestions from '../sections/BlockQuestions';
import More from '../sections/More';
import Table from '../sections/Table';
import PortableText from '../PortableText';
import AuthorList from './AuthorList';
import BackArrow from '../../assets/images/BackArrow.inline.svg';
import LastPosts from './LastPosts';

const BlogPostStyles = styled.div``;

const BlogArticleStyles = styled.article`
  padding-top: var(--margin);
  position: relative;
  z-index: 3;

  .blogpost-title {
    font-weight: 300;
    margin: 0 auto 1.625rem;
    max-width: 52.125rem;
  }

  .excerpt {
    border-bottom: 1.5px solid var(--blue-dark);
    font-weight: 500;
    margin: 0 auto;
    padding-bottom: 0.75rem;
    max-width: 52.125rem;
  }

  .blog-article {
    &__text {
      > div:first-child {
        padding-top: 1rem;
      }
    }
    &__content {
      margin-top: 2em;
    }

    &__meta {
      font-size: 0.85em;
      font-style: italic;
      margin-left: auto;
      margin-right: auto;
      max-width: 52.125rem;
      text-align: right;
    }
  }
`;

export default function BlogPost(props) {
  const { edges, postExcerpt, authors, title, content, image, publishedAt } =
    props;

  return (
    <BlogPostStyles>
      <BlogArticleStyles className="blog-article">
        <div className="container" id="content">
          <Link to="/actualites" className="back">
            <BackArrow className="back__arrow" />
            <span className="back__label">Toutes les actus</span>
          </Link>
          {image && (
            <div className="blog-article__illustr">
              <GatsbyImage
                image={getImage(image?.asset)}
                alt={title}
                className="image"
              />
            </div>
          )}
          <div className="blog-article__content">
            <h1 className="blogpost-title">{title}</h1>
            {postExcerpt && (
              <PortableText blocks={postExcerpt._rawText} id="excerpt" />
            )}
            <p className="blog-article__meta">
              {authors.length > 0 && <AuthorList items={authors} />}
              {publishedAt && (
                <>
                  le&nbsp;
                  {format(new Date(publishedAt), 'dd MMMM yyyy', {
                    locale: fr,
                  })}
                </>
              )}
            </p>
            <div className="blog-article__text">
              <MapToComponents
                getKey={(section) => section.id || section._key}
                getType={(section) => section._type}
                list={content}
                map={{
                  threeColumns: ThreeColumns,
                  twoColumns: TwoColumns,
                  singleColumn: SingleColumn,
                  hero: Hero,
                  more: More,
                  blockQuestions: BlockQuestions,
                  tableau: Table,
                }}
                mapDataToProps={{
                  threeColumns: ({ data }) => ({
                    title: data.title,
                    bgTitle: data.bgTitle?.value,
                    bgSection: data.bgSection?.value,
                    showTitle: data.showTitle,
                    cards: data.cards,
                    titleOptions: data.titleOptions,
                  }),
                  twoColumns: ({ data }) => ({
                    bgColor: data.bgColor?.value,
                    alignContent: data.alignContent,
                    cards: data.cards,
                    colsProportions: data.colsProportions,
                    ctaButton: data.ctaButton,
                    title: data.title,
                    titleOptions: data.titleOptions,
                  }),
                  singleColumn: ({ data }) => ({
                    background: data.background?.value,
                    description: data.columnDescription?._rawText,
                    alignCenter: data.alignCenter,
                    ctaButton: data.ctaButton,
                    title: data.title,
                    showTitle: data.showTitle,
                    titleOptions: data.titleOptions,
                  }),
                  hero: ({ data }) => ({
                    images: data.images,
                  }),
                  steps: ({ data }) => ({
                    title: data.title,
                    steps: data.steps,
                  }),
                  more: ({ data }) => ({
                    text: data.moreText,
                    ctaButton: data.ctaButton,
                  }),
                  tableau: ({ data }) => ({
                    rows: data._rawDataTable.rows,
                  }),
                  blockQuestions: ({ data }) => ({
                    background: data.background?.value,
                    questions: data.questionsList,
                    showTitle: data.showTitle,
                    title: data.title,
                    titleOptions: data.titleOptions,
                  }),
                }}
              />
            </div>
          </div>
          {edges.length > 0 && <LastPosts posts={edges} />}
        </div>
      </BlogArticleStyles>
    </BlogPostStyles>
  );
}
