import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { getBlogUrl } from '../../lib/helpers';
import { mq } from '../../styles/breakpoints';
import AltThumbnail from '../../assets/images/alt-thumbnail.inline.svg';

const LastPostsStyles = styled.div`
  margin-top: var(--padding);
  padding: 0 0 var(--padding);

  .section-title {
    font-size: 1.875rem;
    margin: auto;
    max-width: 52.125rem;
    text-align: center;
  }

  .posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.75rem;

    ${mq[1]} {
      flex-wrap: nowrap;
    }
  }

  .last-post {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--padding);
    padding: 0 1rem;
    text-align: center;
    width: 100%;

    .title-middle {
      font-size: 1.5rem;
    }

    .date {
      /* color: var(--blue); */
      font-size: 0.8rem;
      margin-top: 0.3125rem;
      margin-bottom: 0.5em;
    }
  }

  svg {
    width: 12.25rem;
  }

  .gatsby-image-wrapper {
    border-radius: 100%;
    height: 12.25rem;
    margin-bottom: 1.5625rem;
    width: 12.25rem;

    img {
      border-radius: 100%;
    }
  }

  .button {
    margin-top: calc(var(--margin) / 2);

    ${mq[1]} {
      margin-top: auto;
    }
  }
`;

export default function LastPosts({ posts }) {
  return (
    <LastPostsStyles>
      <h2 className="section-title">Articles récents</h2>
      <div className="posts-list">
        {posts?.map((post) => (
          <div key={post.node.id} className="last-post">
            <Link
              to={getBlogUrl(post.node.publishedAt, post.node.slug.current)}
            >
              {post?.node?.thumbnail ? (
                <GatsbyImage
                  image={getImage(post?.node?.thumbnail.asset)}
                  alt={post?.node?.thumbnail.asset.altText || post?.node?.title}
                />
              ) : (
                <AltThumbnail />
              )}
            </Link>
            <div className="last-post__content">
              <h3 className="title-middle">
                <Link
                  to={getBlogUrl(post.node.publishedAt, post.node.slug.current)}
                >
                  {post.node.title}
                </Link>
              </h3>
              <p className="date">
                {format(new Date(post.node.publishedAt), 'dd MMMM yyyy', {
                  locale: fr,
                })}
              </p>
            </div>
            <Link
              to={getBlogUrl(post.node.publishedAt, post.node.slug.current)}
              className="button"
            >
              En savoir plus
            </Link>
          </div>
        ))}
      </div>
    </LastPostsStyles>
  );
}
