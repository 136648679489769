import React from 'react';
import { graphql } from 'gatsby';
import BlogPost from '../components/blog/BlogPost';
import SEO from '../components/SEO';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import MoreQuery from '../fragments/More';
import TableauQuery from '../fragments/Tableau';
import BlockQuestionsQuery from '../fragments/BlockQuestions';

export default function BlogPostTemplate({ data, location }) {
  const { post, lastposts } = data;

  return (
    <>
      <SEO
        title={post.titleSeo || post.title}
        description={post.descriptionSeo}
        location={location}
        image={post.mainImage?.asset.fluid.src}
      />
      {post && (
        <BlogPost location={location} {...post} {...lastposts} id="content" />
      )}
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      image {
        asset {
          gatsbyImageData(width: 1600)
        }
      }
      title
      slug {
        current
      }
      titleSeo
      descriptionSeo
      postExcerpt {
        _rawText(resolveReferences: { maxDepth: 5 })
      }
      authors {
        _key
        author {
          name
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
        ...HeroQuery
        ...BlockQuestionsQuery
        ...MoreQuery
        ...TableauQuery
      }
    }
    lastposts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          thumbnail {
            hotspot {
              y
              x
              width
              height
            }
            asset {
              altText
              gatsbyImageData(width: 200, height: 200)
            }
          }
          image {
            asset {
              gatsbyImageData(width: 200, height: 200)
            }
          }
          title
          postExcerpt {
            _rawText(resolveReferences: { maxDepth: 10 })
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
